import init from "./init";
import parseAdminDomainState from "./parseAdminDomainState";
import setAdminDomainState from "./setAdminDomainState";
import addAnimationFrame from "./addAnimationFrame";
import animate from "./animate";
import promisify from "./promisify";
import getSubdomain from "./getSubdomain";
import joinRoom from "./joinRoom";
import readState from "./readState";
import authenticate from "./authenticate";
import route from "./route";
import routeExternal from "./routeExternal";
import readToken from "./readToken";
import signOut from "./signOut";
import writeToken from "./writeToken";
import route404 from "./route404";
import e from "./eval";
import scrollLock from "./scrolllock";
import verifyRegisterUser from "./verifyregisteruser";
import getCallType from "./getCallType";
import setQueryParams from "./setQueryParams";
import consumeOATH2Code from "./consumeOATH2Code";
import subscribe from "./subscribe";

const animationFrames: Array<Function> = [];
const rooms: { [key: string]: boolean } = {};

export default (D: any, setD: Function, Cookies: any, App: any) => {
  const _: { [key: string]: any } = {};
  _.init = init(_);
  _.promisify = promisify(D, setD, _);
  _.parseAdminDomainState = parseAdminDomainState(_);
  _.setAdminDomainState = setAdminDomainState(_);
  _.verifyRegisterUser = verifyRegisterUser(_);
  _.animate = animate(_, animationFrames);
  _.addAnimationFrame = addAnimationFrame(_, animationFrames);
  _.getSubdomain = getSubdomain(_);
  _.joinRoom = joinRoom(rooms, _);
  _.consumeOATH2Code = consumeOATH2Code(_);
  _.readState = readState(_);
  _.authenticate = authenticate(_);
  _.route = route(_);
  _.setQueryParams = setQueryParams(_);
  _.routeExternal = routeExternal(_);
  _.readToken = readToken(_, new Cookies());
  _.signOut = signOut(_);
  _.writeToken = writeToken(_, new Cookies());
  _.e = e(_, D);
  _.scrollLock = scrollLock(App);
  _.route404 = route404(_);
  _.getCallType = getCallType;
  _.subscribe = subscribe(_);
  return _;
};
