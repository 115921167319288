import PickList from "../../../picklist";
import TextField from "../../../textfield";
import Button from "../../../button";
import Monaco from "../../../monaco";

export default {
	PickList,
	TextField,
	Button,
	Monaco,
};
