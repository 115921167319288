import styled from "styled-components";

const Container = styled.div``;

const DescriptionComponent = styled.div``;

export default {
  Container,
  DescriptionComponent,
};
