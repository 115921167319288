import styled from "styled-components";

const Container = styled.div``;

const Body = styled.div``;

const HeroBody = styled.div``;

const HeroImg = styled.img``;

const HeroText = styled.div``;

export default {
  Container,
  HeroBody,
  HeroText,
  Body,
  HeroImg,
};
