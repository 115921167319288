import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

const SubContainer = styled.div``;

export default {
  Container,
  SubContainer,
};
