import React, { FC } from "react";
import { Button, Page, TextField } from "../../../components";
import init from "../../../projectcomponents/init";

const UserSignUp: FC<{
  fns: {
    [key: string]: any;
  };
  D: { [key: string]: any };
  endpoint?: string;
}> = ({ fns, D, endpoint }) => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  React.useEffect(() => init(fns, D), [fns]);
  let obj = { _passwordresettoken: undefined };
  try {
    obj = JSON.parse(atob(fns.readState().query.v));
  } catch (e) {}
  return D && fns.calls?.registeruser && fns.calls?.resetuserpassword ? (
    <Page
      fns={fns}
      backgroundImage={{
        src: `https://highmountainlabs.io/arclight/static/media/65e3b40035b8895019faa204.jpg`,
        opacity: 0.0,
      }}
    >
      <div className={`arclight-flex arclight-w-full arclight-h-screen`}>
        <div
          className={`arclight-flex-col arclight-space-y-10 arclight-m-auto arclight-w-96`}
        >
          {!fns.readState().query.v ? (
            <React.Fragment>
              <TextField
                key={0}
                hot
                value={username}
                type={""}
                variant={"standard"}
                onChange={(e) => setUsername(e.target.value)}
                label={"Username"}
                span
              />
              <TextField
                key={0}
                hot
                value={password}
                variant={"standard"}
                type={"password"}
                onChange={(e) => setPassword(e.target.value)}
                label={"password"}
                span
              />
              <Button
                onClick={() =>
                  fns.calls.registeruser({
                    redirect: "http://localhost:3000/lab-usersignup",
                    email: "broc.roberts2@gmail.com",
                    username,
                    password,
                  })
                }
                type={"standard"}
                size={"md"}
                animation={false}
                label={`Sign Up`}
                span
              />
              <Button
                onClick={() =>
                  fns.calls.resetuserpassword({
                    username,
                    redirect: "http://localhost:3000/lab-usersignup",
                  })
                }
                type={"standard"}
                size={"md"}
                animation={false}
                label={`Reset Password`}
                span
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <TextField
                key={0}
                hot
                value={password}
                variant={"standard"}
                type={"password"}
                onChange={(e) => setPassword(e.target.value)}
                label={"password"}
                span
              />
              <Button
                onClick={() => {
                  if (obj._passwordresettoken) {
                    fns.calls.verifyresetuserpassword({
                      v: fns.readState().query.v,
                      password,
                    });
                  } else {
                    fns.calls.verifyregisteruser({
                      v: fns.readState().query.v,
                      password,
                    });
                  }
                }}
                type={"standard"}
                size={"md"}
                animation={false}
                label={
                  obj._passwordresettoken
                    ? `Reset User Password`
                    : `Verify User Registration`
                }
                span
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </Page>
  ) : null;
};

export default UserSignUp;
