import styled from "styled-components";

const Container = styled.div``;

const Body = styled.div``;

const Nopage = styled.img`
	width: 100%;
	object-fit: cover;
`;

export default {
	Container,
	Nopage,
	Body,
};
