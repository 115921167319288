import styled from "styled-components";

const Container = styled.div``;

const Body = styled.div`
	position: relative;
	min-height: 100vh;
`;

export default {
	Container,
	Body,
};
